




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
  public page: string = '';

  public created() {
    this.page = this.$route.path.replace('/', '');
  }
}
